import React from "react"
import { Link } from "gatsby"
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from "react-icons/fa"
const footer = props => {
	return (
		<div class="footer-clean">
			<footer>
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-7 col-xl-8 item">
							<h3 class="btn-primary py-1 px-3">QUICK LINKS</h3>
							<ul class="list-inline">
								<li class="list-inline-item">
									<Link to="#">Home</Link>
								</li>

								<li class="list-inline-item">
									<Link to="/services">Services</Link>
								</li>
								<li class="list-inline-item">
									<Link to="/ask-a-tech">Ask a Tech</Link>
								</li>

								<li class="list-inline-item">
									<Link to="/contact">Contact us</Link>
								</li>
							</ul>
						</div>
						<div class="col-5 col-xl-4 item social">
							<a
								href="https://www.facebook.com/iboilerworx/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="fas">
									<FaFacebookF />
								</div>
							</a>
							<a
								href="https://twitter.com/iboilerworx"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="fas">
									<FaTwitter />
								</div>
							</a>
							<a
								href="https://linkedin.com/company/intermountain-boilerworx-inc"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="fas">
									<FaLinkedinIn />
								</div>
							</a>
							<a
								href="https://www.youtube.com/channel/UC39780sKj7T_F08g_wn72ew"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="fas">
									<FaYoutube />
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="container mt-5 copyright">
					<div class="row">
						<div class="col-sm-6 text-center text-sm-left">
							<p>
								©2020&nbsp;At Intermountain BoilerworX, Inc. All Rights Reserved
								<br />
							</p>
						</div>
						<div class="col-sm-6 text-center text-sm-right">
							<p>
								Designed and Powered by&nbsp;
								<a
									href="http://webriq.com/"
									target="_blank"
									style={{ color: "#f99820" }}
								>
									<strong>WebriQ</strong>
								</a>
								<br />
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default footer
