import React, { useState } from "react"
import { Button, Modal } from "reactstrap"

const ModalPopUp = props => {
	const { className } = props

	const [modal, setModal] = useState(false)

	const toggle = () => setModal(!modal)

	return (
		<div>
			<Button className="btn-1 main-cta btn btn-secondary" onClick={toggle}>
				Free Consultation
			</Button>
			<Modal isOpen={modal} toggle={toggle} className={className}>
				<iframe
					title="calendly"
					src="https://calendly.com/alex-belding/lets-build-a-website"
					height="500"
				/>
			</Modal>
		</div>
	)
}
export default ModalPopUp
