import React, { useState } from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import logo from "../../images/logo.png"
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaMapMarker,
  FaPhone,
} from "react-icons/fa"

import ModalPopUp from "../modal/modal"

const Header = () => (
  <header>
    <div class="container-fluid d-none d-md-block headerstrip">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-xl-7 headerstrip-left-col">
          <div class="py-2">
            <p class="mb-0 ml-3">
              <div className="fas d-inline">
                <FaMapMarker />
              </div>
              &nbsp;270971 Littleton, Colorado, CO. 8012
              <br />
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-5 px-0">
          <div class="d-xl-flex justify-content-end align-items-center px-4 py-2 gradient">
            <p class="float-left text-white mr-sm-1 mr-md-2 mr-lg-5 mb-0">
              Open 8am-5pm:&nbsp;Monday - Saturday
            </p>
            <nav className="navbar navbar-expand">
              <ul class="navbar-nav">
                <li class="nav-item active">
                  <a
                    href="https://www.facebook.com/iboilerworx/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="fas">
                      <FaFacebookF />
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="https://twitter.com/iboilerworx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="fas">
                      <FaTwitter />
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="https://linkedin.com/company/intermountain-boilerworx-inc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="fas">
                      <FaLinkedinIn />
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="https://www.youtube.com/channel/UC39780sKj7T_F08g_wn72ew"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="fas">
                      <FaYoutube />
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className={`header ${styl.header}`}>
        <div class="container-fluid header-navigation shadow">
          <div class="row">
            <div class="col-7 col-md-4 px-0">
              <Link to="/">
                <img class="logo img-fluid" src={logo} />
              </Link>
            </div>
            <div class="col-5 col-md-8">
              <div class="d-flex justify-content-end align-items-center header-navigation_cta">
                <div class="d-none d-lg-block call-us mr-5">
                  <FaPhone
                    className="mr-2"
                    style={{ transform: "rotate(90deg)" }}
                  />
                  <span>24/7 SUPPORT</span>
                  <a href="tel:3038450432">303-845-0432</a>
                </div>
                <div class="d-none d-md-block free-consultation mr-5">
                  <Link
                    to="/contact"
                    className="btn-1 main-cta btn btn-secondary"
                  >
                    Free Consultation
                  </Link>
                </div>
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
